/* Estilos para el modal */
.modal {
    display: none; /* Oculto por defecto */
    position: fixed; /* Fijo en la pantalla */
    z-index: 1; /* Encima de todo el contenido */
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto; /* Habilitar el scroll si es necesario */
    background-color: rgba(0, 0, 0, 0.5); /* Fondo negro con opacidad */
}

/* Contenido del modal */
.modal-content {
    background-color: #fff;
    margin: 15% auto; /* Centrado verticalmente y horizontalmente */
    padding: 20px;
    border: 1px solid #888;
    width: 80%; /* Ancho del modal */
    max-width: 600px; /* Ancho máximo */
}

/* Botón de cierre */
.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}
