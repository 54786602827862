.fade-enter {
    opacity: 0;
}

.fade-enter-active {
    opacity: 1;
    transition: opacity 0.5s;
}

.fade-exit {
    opacity: 1;
}

.fade-exit-active {
    opacity: 0;
    transition: opacity 0.5s;
}
.image-container {
    margin: 25px;
    border-radius: 15px;
    box-shadow: 0 4px 10px rgb(255, 255, 255);
    overflow: hidden;
    max-width: 100%;
}

.image-container img {
    display: block;
    width: 100%;
    height: auto;
}
